<template>
	<div class="attestation">
		<div class="autonym-box">
			<div class="title">账户安全 - 实名认证</div>
			<!-- 个人用户注册 -->
			<div class="register-containe">
				<el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px">
					<!-- 用户姓名 -->
					<el-form-item label="用户姓名：" prop="realName">
						<el-input v-model="ruleForm.realName" placeholder="请输入您的真实姓名" class="phone-num" clearable>
						</el-input>
					</el-form-item>

					<!-- 身份证号 -->
					<el-form-item label="身份证号：" prop="idNumber">
						<el-input class="code-input" v-model="ruleForm.idNumber" maxlength="18"
							placeholder="请输入身份证证件号码" clearable>
						</el-input>
					</el-form-item>
					
					<!-- 营业执照 -->
					<el-form-item label="营业执照：" prop="certificateNo" v-if="type == 2">
						<el-input class="code-input" v-model="ruleForm.certificateNo" maxlength="18"
							placeholder="请输入营业执照编码" clearable>
						</el-input>
					</el-form-item>
					<!--  -->
					<el-form-item label="企业名称：" prop="companyName" v-if="type == 2">
						<el-input class="code-input" v-model="ruleForm.companyName" maxlength="20"
							placeholder="请输入企业名称" clearable>
						</el-input>
					</el-form-item>
				</el-form>

				<div class="mechreal-title">
					<p>上传身份证照片</p>
					<span>请按照以下要求上传身份证照片（格式要求：jpg、jpeg、png，小于2M） </span>
					<em class="picerror-msg" style="display: none;">请上传完照片后再次提交！</em>
				</div>
				<!-- 上传照片 -->
				<div class="personalitem mb20">
					<!-- 正面 -->
					<div class="front">
						<p>上传身份证人像面</p>							
						<el-upload action="" class="upload mt10"
							:http-request="(res,file)=>{httpRequest('1',res,file)}"
							list-type="picture-card" 
							:limit="1"
							:on-remove="handleRemove"
							title="个人信息页">
							<i class="el-icon-plus"></i>
						</el-upload>
					</div>
					<!-- 反面 -->
					<div class="front">
						<p>上传身份证国徽面</p>
						<el-upload action="#" class="upload mt10"
							:http-request="(res,file)=>{httpRequest('2',res,file)}"
							list-type="picture-card" 
							:limit="1"
							:on-remove="handleRemove"
							title="国徽页">
							<i class="el-icon-plus"></i>
						</el-upload>
					</div>
				</div>
				<!-- 示例 -->
				<div class="picexampleUse mb20">
					<div class="use" v-for="(item,index) in exampleItem" :key="index" @click="onExample(index)">
						<span>示例：</span>
						<img v-if="item.size == 1" :src="item.imgSmall" data-bol="true" data-num="0">
						
						<img v-else :src="item.imgBig" data-bol="true" data-num="0">
					</div>
				</div>
				
				<!-- 营业执照 -->
				<div class="personalitem mb20" v-if="type == 2">
					<div class="front">
						<p>上传营业执照</p>
						<el-upload action="#" class="upload mt10"
							:http-request="(res,file)=>{httpRequest('3',res,file)}"
							list-type="picture-card" 
							:limit="1"
							:on-remove="handleRemove"
							title="营业执照">
							<i class="el-icon-plus"></i>
						</el-upload>
					</div>
				</div>
				
				<!-- 提交按钮 -->
				<div class="submitBtn" @click="submitBtn">
					<p>提交</p>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	// import loginHeader from '@/components/login/loginHeader.vue'
	import {upload, identityAuth} from '@/axios/api.js'
	import { ID_REG } from '@/utils/reg.js'
	export default {
		data() {
			var validateIdNumber = (rule, value, callback) => {
				if (value === '') {
					callback(new Error('请输入身份证号'));
				} else if (!ID_REG.test(this.ruleForm.idNumber)) {
					callback(new Error('请输入正确的身份证号'));
				} else {
					callback();
				}
			};
			return {
				// type: this.$route.query.type,
				type: "",
				ruleForm: {
					realName: '',
					idNumber: '',
					certificateNo: '',
					companyName: ''
				},
				rules: {
					realName: [{
						required: true,
						message: '请输入真实姓名',
						trigger: 'blur'
					}],
					idNumber: [{
						required: true,
						validator: validateIdNumber,
						trigger: 'blur'
					}],
					certificateNo: [{
						required: true,
						message: '请输入营业执照编号',
						trigger: 'blur'
					}],
					companyName: [{
						required: true,
						message: '请输入企业名称',
						trigger: 'blur'
					}],
				},
				exampleItem: [{
					imgSmall: require('../../assets/img/login/cardfontsmall.png'),
					imgBig: require('../../assets/img/login/cardfontbig.png'),
					size: 1,
				},{
					imgSmall: require('../../assets/img/login/cardendsmall.png'),
					imgBig: require('../../assets/img/login/cardendbig.png'),
					size: 1,
				}],
				portrait: '', //身份证正面图片地址
				emblem: '', //身份证背面图片地址
				charter: '', //营业执照图片地址
			}
		},
		components: {
			// loginHeader
		},
		created() {
			let userInfo = JSON.parse(sessionStorage.getItem('userInfo')) 
			this.type = userInfo.type
		},
		methods: {
			// 点击示例放大缩小图片
			onExample(idx){
				this.exampleItem.forEach((item,index)=>{
					if(index == idx){
						if(item.size == 1){
							item.size = 2
							return
						}
						if(item.size == 2){
							item.size = 1
						}
					}
				})
				
			},
			// 上传图片接口请求
			httpRequest(type,file) {
				// console.log('自定义上传' ,type,file)
				let that = this
				var data = new FormData()
				data.append('file', file.file)
				upload(data).then(function(res){
					console.log('upload:', res.data)
					let data = res.data
					if(data.code == 0){
						if(type == '1'){ //1-身份证正面,1-身份证背面,3-营业执照
							that.portrait = data.result
							return
						}
						if(type == '2'){
							that.emblem = data.result
							return
						}
						if(type == '3'){
							that.charter = data.result
						}
					}
					
					
				})
				.catch(function(err){
					console.log('err:', err)
				})
			},
			//文件移除方法
			handleRemove(file, fileList) {
				let _this = this;
				console.log(file, fileList);
				
			},
			// 提交
			submitBtn(){				
				console.log(this.type)
				if(!this.ruleForm.realName){
					this.$message.error('请输入真实姓名');
					return
				}
				if(!this.ruleForm.idNumber){
					this.$message.error('请输入身份证号');
					return
				}
				if(!ID_REG.test(this.ruleForm.idNumber)){
					this.$message.error('请输入正确的身份证号');
					return
				}
				if(this.type == 2){
					if(!this.ruleForm.certificateNo){
						this.$message.error('请输入营业执照编号')
						return
					}
					if(!this.ruleForm.companyName){
						this.$message.error('请输入企业名称')
						return
					}
				}
				if(!this.portrait){
					this.$message.error('请上传身份证正面');
					return
				}
				if(!this.emblem){
					this.$message.error('请上传身份证背面');
					return
				}
				
				if(this.type == 2){
					if(!this.charter){
						this.$message.error('请上传营业执照')
						return
					}
				}
				let that = this
				let data = {
					type: this.type,//1-个人认证,2-企业认证
					idNumber: this.ruleForm.idNumber,//身份证/法人身份证
					realname: this.ruleForm.realName,//	姓名/法人
					idFrontImg: this.portrait, //身份证正面图片地址
					idBackImg: this.emblem, //身份证背面图片地址
				}
				if(this.type == 2){
					data['certificateNo'] = this.ruleForm.certificateNo//营业执照编号（type=2时填写
					data['certificateImg'] = this.charter //营业执照图片地址（type=2时填写）
					data['companyName'] = this.ruleForm.companyName //企业名称（type=2时填写）
				}
				identityAuth(data).then(function(res) {
					console.log('identityAuth:', res.data)
					let data = res.data
					if (data.code == 0) {
						that.$router.push('/index');
					} else {
						that.$message({
							message: data.msg,
							type: 'error'
						})
					}
				})
				.catch(function(err) {
					console.log('err:', err)
				})
			}
		}
	}
</script>

<style lang="less" scoped>
	.attestation {
		min-height: 100vh;
		background: #fff;

		.pl200 {
			padding-left: 200px;
		}
		.pl50{
			padding-left: 50px;
		}
		.title {
			height: 36px;
			padding-left: 20px;
			font-size: 16px;
			background: #e9e9e9;
			font-weight: 500;
			line-height: 36px;
		}

		.register-containe {
			font-size: 18px;
			width: 100%;
			height: 82px/9;
			margin-bottom: 100px;
			padding: 20px 0 0 50px;
			.mechreal-title {
				font-size: 16px;
				margin: 20px 0;
				color: #333;
				display: flex;

				span {
					font-size: 14px;
				}
			}
		}
		.personalitem{
			display: flex;
			color: #333;
			
			.front{
				margin-right: 40px;
				p{
					line-height: 30px;
					font-size: 14px;
				}
			}			
		}
		.picexampleUse{
			display: flex;
			color: #333;
			font-size: 14px;
			overflow: hidden;
			.use{
				width: 275px;
				margin-right: 20px;
				span {
				    display: block;
				    margin-bottom: 10px;
				}
				img {
				    cursor: pointer;
				    border: 1px solid #d7d7d7;
				    border-radius: 8px;
				}
			}
		}
		.submitBtn{
			margin: 40px 0 20px;
			p{
				width: 150px;
				height: 40px;
				line-height: 40px;
				text-align: center;
				font-size: 16px;
				background: #d6363b;
				border: 1px solid transparent;
				color: #fff;
				border-radius: 3px;
			}
		}
	}
	.upload{	
		width: 240px;
		height: 160px;
		overflow: hidden;
		/deep/.el-upload--picture-card{
			width: 240px;
			height: 150px;
			line-height: 150px;
		}		
		/deep/.el-upload-list--picture-card{
			.el-upload-list__item{
				width: 240px;
				height: 150px;
			}
		} 
	}
	/deep/.el-form {
		.el-input {
			width: 220px;
		}
	}
</style>
